(function ($, generic, site) {
  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $blocks.each(function () {
        var $block = $(this);
        var $forgotPass = $('.js-signin-block__forgot-password', $block);

        $forgotPass.on('click', function (e) {
          e.preventDefault();

          var emailAddress = $block.find('input[name=EMAIL_ADDRESS]').val();

          site.signin.requestPassword(emailAddress);
        });
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  $(document).on('user.loaded', function (e, val) {
    // This is brittle but there's no other way to tell from the data we get.
    var first_name = val.first_name;

    // Add name to banner account info
    if (!_.isNull(first_name) || !_.isUndefined(first_name)) {
      $('.js-site-header__firstname').html(first_name);
      $('.js-site-header__account__state').addClass('has__firstname');
    } else {
      $('.js-site-header__account__state').removeClass('has__firstname');
    }

    /* If user is ever signed in, permanently store a "USER_REC" cookie. This
       only expires once a year. */
    if (val.signed_in) {
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
    }
  });
})(jQuery, window.generic || {}, window.site || {});
